import { createTheme } from '@mui/material/styles';

const width = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 767,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          width: '384px',
          [width.breakpoints.down('sm')]: {
            width: '92vw',
          },
          borderRadius: '5px',
          background: '#FFFFFF',
        }
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          position:'absolute',
          width:'100%',
          top:'37px',
          height:'0px'
        }
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 1) !important',
        }
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          // display: 'none',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
            backgroundColor: 'rgb(255 255 255 / 0%)',
        },
      },
    },
  },
});
