import React, { useState, useEffect, useRef } from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { TextField } from "@mui/material";
import InputMask from 'react-input-mask';
import axios from 'axios';
import './layout.scss'


const Layout = () => {

  let lastScroll = 0;

  const inputRef = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);

  window.addEventListener('scroll', () => {

    const header = document.querySelector('header');

    const scrollPosition = () => window.pageYOffset || document.documentElement.scrollTop;
    const cantainHide = () => header.classList.contains('hide');

    if (scrollPosition() > lastScroll && !cantainHide()) {
      header.classList.add('hide')
    } else if (scrollPosition() < lastScroll && cantainHide()) {
      header.classList.remove('hide')
    }

    lastScroll = scrollPosition()
  })



  const [menuMob, setMenuMob] = useState(false);

  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  // const [erdpo, setErdpo] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [btnEm, setBtnEm] = useState(false);

  const [email, setEmail] = useState('');

  const handleEmail = (event) => {
    const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(event)
    res ? setBtnEm(true) : setBtnEm(false)
    setEmail(event);
  };

  const [apiMessage, setApiMessage] = useState('');

  const [galeryVisibl, setGaleryVisibl] = useState(3.5);

  useEffect(() => {
    if (document.documentElement.clientWidth < 1280) {
      setGaleryVisibl(document.documentElement.clientWidth / 381)
    } else if (document.documentElement.clientWidth > 1400) {
      setGaleryVisibl(3.8)
    }
  }, [document.documentElement.clientWidth]);

  const GalleryItem = (props) => {
    return (
      <div className="galleryItem">
        <div className="imgs">
          {props.img && <img src={props.img} />}
          {props.img2 && <img src={props.img2} />}
          {props.img3 && <img src={props.img3} />}
        </div>
        <h1 className="h1">{props.h1}</h1>
        <p className="p">{props.p}</p>
      </div>
    )
  }

  const changePhoneNumber = (event) => {
    setPhoneNumber(event.target.value)
  };

  const beforeMaskedValueChange = (newState, oldState, userInput) => {
    let { value } = newState;
    const selection = newState.selection;
    if (!!userInput) {
      if (userInput.length > 9) {
        value = `+380${userInput.replace(/\s/g, '').slice(-9)}`
      }
    }

    return {
      value,
      selection
    };
  }

  const Gallery = () => {
    return (
      <CarouselProvider
        naturalSlideWidth={13}
        naturalSlideHeight={6}
        totalSlides={5}
        visibleSlides={galeryVisibl}
        dragEnabled={false}
        step={1.5}
      >
        <Slider className='Slider'>
          <Slide index={0}>
            <GalleryItem
              img='/images/Vector.svg'
              img2='/images/layer1.svg'
              img3='/images/Group 537.svg'
              h1='Оплата банківською карткою'
              p='Прийом платежів з пластикових карток Visa та Mastercard на сайті продавця'
            />
          </Slide>
          <Slide index={1}>
            <GalleryItem
              img='/images/Frame (1).svg'
              h1='Кнопка Apple Pay'
              p='З наступним переведенням у безготівковий розрахунок онлайн'
            />
          </Slide>
          <Slide index={2}>
            <GalleryItem
              img='/images/Frame (2).svg'
              h1='Кнопка Google Pay'
              p='З наступним переведенням у безготівковий розрахунок онлайн'
            />
          </Slide>
          <Slide index={3}>
            <GalleryItem
              img='/images/image 5 (1).svg'
              img2='/images/Frame (3).svg'
              img3='/images/image 15.svg'
              h1='Оплата з мобільного телефону'
              p='Прийом грошей із рахунку українського мобільного оператора'
            />
          </Slide>
          <Slide index={4}>
            <GalleryItem
              h1='Оплата готівкою'
              p='З наступним переведенням у безготівковий розрахунок онлайн'
            />
          </Slide>
        </Slider>
        <div className="btn-group-swipe">
          <ButtonBack className='back-btn-swipe'><img src="/images/Group 642.svg" /></ButtonBack>
          <ButtonNext className='next-btn-swipe'><img src="/images/Group 642 (1).svg" /></ButtonNext>
        </div>
      </CarouselProvider>

    )
  }

  const baseUrl = 'https://tech.easypay.ua/';

  const instance = axios.create({
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
    baseURL: `${baseUrl}`
  })


  function setLendingDataUser(lastName, firstName, phoneNumber, email) {
    instance
      .post(`api/acquiring/contact-us`, { email: email, name: firstName, phone: phoneNumber })
      .then(response =>
        console.log('response.data = ', response.data))
      .catch(error => console.log('error.message = ', error.message));
  }

  return (
    <div className="layout">
      <div className={menuMob ? "menu-mob-land" : "menu-mob-land hide"}>
        <div className="top">
          <img onClick={() => setMenuMob(false)} width="24px" height="24px" src="/images/Clear.png" />
          <button className="signIn-btn" onClick={() => document.querySelector('.form').scrollIntoView({ behavior: 'smooth' })}>
            Авторизацiя
          </button>
        </div>
        <div className="bot">
          <p onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>Проекти</p>
          <p onClick={() => document.querySelector('.tariffs').scrollIntoView({ behavior: 'smooth' })}>Тарифи</p>
          <p onClick={() => document.querySelector('.license').scrollIntoView({ behavior: 'smooth' })}>Безпека</p>
          <p onClick={() => window.open('https://easypay.ua/ua/moneytransfer')}>Грошові перекази</p>
          <p onClick={() => window.open('https://easypay.ua/ua/catalog/popular')}>Оплата послуг</p>
          <button className="phone-btn-nav" >
            <img className='logoMC' src="/images/phone.svg" />
            0 800 60 30 03
          </button>
        </div>
      </div>
      <header className="header" id='nav'>
        <img onClick={() => setMenuMob(!menuMob)} className="menu-btn-mob-land" src="/images/list.png" />
        <img src="/images/image.svg" />
        <img className="exit-btn-mob-land" onClick={() => document.querySelector('.form').scrollIntoView({ behavior: 'smooth' })} src="/images/Exit (1).svg" />
        <div className="group-btns">
          <button className="items-nav" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
            Проекти
          </button>
          <button className="items-nav" onClick={() => document.querySelector('.tariffs').scrollIntoView({ behavior: 'smooth' })}>
            Тарифи
          </button>
          <button className="items-nav" onClick={() => document.querySelector('.license').scrollIntoView({ behavior: 'smooth' })}>
            Безпека
          </button>
          <button className="items-nav" onClick={() => window.open('https://easypay.ua/ua/moneytransfer')}>
            Грошові перекази
          </button>
          <button className="items-nav" onClick={() => window.open('https://easypay.ua/ua/catalog/popular')}>
            Оплата послуг
          </button>
        </div>
        <div className="group-btns">
          <button className="phone-btn-nav" >
            <img className='logoMC' src="/images/phone.svg" />
            0 800 60 30 03
          </button>
          <button className="signIn-btn" onClick={() => document.querySelector('.form').scrollIntoView({ behavior: 'smooth' })}>
            Авторизацiя
          </button>
        </div>
      </header>
      <main className="main">
        <img className='back-img-first' src="/images/Mask group.svg" />
        <img className='back-img-big' src="/images/backGroundBig.svg" />
        <img className='back-img-first-mob' src="/images/fone-mob.svg" />
        <img className='back-img-analit' src="/images/Аналiтика.svg" />
        <img className='back-img-analit-mob' src="/images/Аналiтiка.svg" />
        <div className="works-img">
          <img className='person' src="/images/Frame.svg" />
          <p>Кваліфікований персонал</p>
        </div>
        <div className="coll-img">
          <img src="/images/Support (1).svg" />
          <p>Контакт-центр 24/7</p>
        </div>
        <div className="leftContent">
          <h1 className="h1">Сучасні платіжні рішення для вашого сайту</h1>
          <p >Платіжний сервіс для бізнесу</p>
          <button onClick={() => document.querySelector('.form').scrollIntoView({ behavior: 'smooth' })}>Підключити</button>
        </div>
        <div className="logos">
          <div className="top-lane">
            <img src="/images/Group.svg" />
            <img src="/images/image 4.svg" />
            <img src="/images/Group 698.svg" />
            <img src="/images/image 17.svg" />
            <img src="/images/image 9.svg" />
            <img src="/images/image 5.svg" />
          </div>
          <div className="bot-lane">
            <img height="28px" src="/images/image 2 (1).svg" />
            <img height="38px" src="/images/image 7.svg" />
            <img height="38px" src="/images/image 10.svg" />
            <img height="38px" src="/images/image 8.svg" />
            <img height="38px" src="/images/image 14.svg" />
          </div>
        </div>
      </main>
      <div className="swipe-list">
        <h1>Сучасні платіжні системи для вашого сайту</h1>
        <p>Зробіть платежі у вашому інтернет-магазині зручними для кожного клієнта. Налаштуйте різні способи розрахунку за допомогою EasyPay – найзатребуванішої платіжної системи України та почніть отримувати прибуток уже завтра.</p>
        <Gallery />
      </div>
      <div className="map-uk">
        <img className="map-img" src="/images/Map.svg" />
        <img className="map-img-mob" src="/images/map-m.png" />
        <div className="text">
          <h1>Платіжний сервіс для бізнесу</h1>
          <div className="map-info">
            <div className="item">
              <h2>15 років</h2>
              <p>на ринку</p>
            </div>
            <div className="item">
              <h2>> 20 тис.</h2>
              <p>терміналів</p>
            </div>
            <div className="item">
              <h2>7 000+</h2>
              <p>партнерів по Україні</p>
            </div>
            <div className="item">
              <h2>10 000 000</h2>
              <p>платежів щомісяця</p>
            </div>
            <div className="item">
              <h2>500 000</h2>
              <p>користувачів EasyPay</p>
            </div>
          </div>
        </div>
      </div>
      <div className="anim">
        <div className="left">
          <h2>Порядок співробітництва та підключення</h2>
          <button onClick={() => document.querySelector('.form').scrollIntoView({ behavior: 'smooth' })}>Підключити</button>
        </div>
        <div className="right">
          <div className="anim-points">
            <div className="first" />
            <div className="vector" />
            <div className="between" />
            <div className="vector2" />
            <div className="second" />
            <div className="vector3" />
            <div className="between2" />
            <div className="vector3" />
            <div className="third" />
            <div className="vector4" />
            <div className="between3" />
            <div className="vector4" />
            <div className="fourth" />
          </div>
          <div className="items-group">
            <div className="item one">
              <img className="img-a-color" src="/images/a1.svg" />
              <div>
                <p>Визначення вимог клієнта</p>
                <p>Узгодження комерційних умов</p>
              </div>
            </div>
            <div className="item two">
              <img className="img-a-color" src="/images/a2.svg" />
              <div>
                <p>Надання повного пакету документів</p>
                <p>Підписання договору</p>
              </div>
            </div>
            <div className="item three">
              <img className="img-a-color" src="/images/a3.svg" />
              <div>
                <p>Технічна інтеграція системи EasyPay</p>
                <p>Налаштування особистого кабінету</p>
              </div>
            </div>
            <div className="item four">
              <img className="img-a-color" src="/images/a4.svg" />
              <div>
                <p>Тестування прийому платежів на сайті</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="benefits">
        <h2>Наші переваги</h2>
        <div className="row">
          <div className="item">
            <img className="img-b-color" src="/images/b1.svg" />
            <p>Технологічний процесинг</p>
          </div>
          <div className="item">
            <img className="img-b-color" src="/images/b2.svg" />
            <p>Привабливі умови співробітництва</p>
          </div>
          <div className="item">
            <img className="img-b-color" src="/images/b3.svg" />
            <p>Власний штат ІТ-фахівців (In-house)</p>
          </div>
        </div>
        <div className="row">
          <div className="item">
            <img className="img-b-color" src="/images/b4.svg" />
            <p>Кваліфікований персонал</p>
          </div>
          <div className="item">
            <img className="img-b-color" src="/images/b5.svg" />
            <p>Цілодобовий контакт-центр 24/7</p>
          </div>
          <div className="item">
            <img className="img-b-color" src="/images/b6.svg" />
            <p>Різноманітні методи прийому платежів</p>
          </div>
        </div>
      </div>
      <div className="users">
        <div className="left">
          <h2>Наші рішення використовують</h2>
          <button onClick={() => document.querySelector('.form').scrollIntoView({ behavior: 'smooth' })}>Співпрацювати</button>
        </div>
        <div className="right">
          <div className="row">
            <div className="item one">
              <img src="/images/c1.svg" />
              <p>Телекомунікаційні компанії</p>
            </div>
            <div className="item two">
              <img src="/images/c2.svg" />
              <p>Агентства з оренди нерухомості</p>
            </div>
          </div>
          <div className="row">
            <div className="item three">
              <img src="/images/c3.svg" />
              <p>Провайдери інтернету</p>
            </div>
            <div className="item four">
              <img src="/images/c4.svg" />
              <p>Інтернет магазини</p>
            </div>
            <div className="item five">
              <img src="/images/c5.svg" />
              <p>Туристичні компанії</p>
            </div>
          </div>
          <div className="row">
            <div className="item six">
              <img src="/images/c6.svg" />
              <p>Готелі</p>
            </div>
            <div className="item seven">
              <img src="/images/c7.svg" />
              <p>Страхові компанії</p>
            </div>
            <div className="item eight">
              <img src="/images/c8.svg" />
              <p>Автотранспортні компанії</p>
            </div>
          </div>
        </div>
      </div>
      <div className="cabinet">
        <h2>Багатофункціональний особистий кабінет</h2>
        <div className="rows">
          <div className="row">
            <div className="item">
              <h3>Конструктор платіжних кнопок</h3>
              <p>Використовуйте, щоб налаштовувати дизайн платіжних форм сайту за своїми вимогами та корпоративними стандартами.</p>
            </div>
            <div className="item">
              <h3>Управління платежами</h3>
              <p>Підтверджуйте або скасовуйте прийом коштів, оформляйте повернення по інтернету.</p>
            </div>
            <div className="item">
              <h3>Управління рахунками</h3>
              <p>Виставляйте їх по email або за допомогою смс-повідомлень.</p>
            </div>
          </div>
          <div className="row">
            <div className="item">
              <h3>Керування продажами</h3>
              <p>Аналізуйте динаміку сайту, кількість платежів, розмір середнього чека.</p>
            </div>
            <div className="item">
              <h3>Звітність</h3>
              <p>Складайте форми реєстрів за потрібний період, фільтруйте дані за датою прийому, сумою та статусом платежу.</p>
            </div>
            <div className="item">
              <h3>Управління правами доступу</h3>
              <p>На ваш запит ми налаштуємо їх залежно від функціональних ролей уповноважених співробітників.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="pay-buttons">
        <div className="left">
          <h2>Конструктор платіжних кнопок</h2>
          <p>Вам не доведеться ламати голову над тим, як налаштувати оплату на сайті. Просто скопіюйте html-код із особистого кабінету та вставте туди, де хочете розмістити платіжну кнопку для прийому коштів. Завантажуйте готові шаблони або користуйтеся зручним інтерфейсом, щоб настроїти сторінку оплати вручну.</p>
          <button onClick={() => document.querySelector('.form').scrollIntoView({ behavior: 'smooth' })}>Співпрацювати</button>
        </div>
        <img className='inv-img-lay' src="/images/Inv.svg" />
      </div>
      <div className="pays">
        <div className="column">
          <div className="item">
            <h2>Рекурентні платежі</h2>
            <p>Вдале рішення для роботи з передплати. У чому полягає суть прийому? При здійсненні першого платежу покупець дозволяє системі списувати певну суму, і всі подальші транзакції здійснюються автоматично на запит інтернет-магазину. Це зменшить кількість прострочених платежів, і ви зможете чіткіше прогнозувати доходи від сайту.</p>
          </div>
          <div className="item">
            <h2>Міжнародні транзакції</h2>
            <p>Здійснюйте прийом платежів з усього світу. Система EasyPay забезпечить своєчасне зарахування коштів з будь-яких валютних карток на гривневий рахунок. Конвертація здійснюється за курсом банку-емітента. Тож жодних проблем із валютним контролем.</p>
          </div>
        </div>
        <div className="column">
          <div className="item">
            <h2>Миттєві платежі</h2>
            <p>Зробіть процес оплати на сайті ще простіше та швидше. Запропонуйте клієнту зберегти реквізити його платіжної картки, щоб не вводити їх заново під час наступних покупок. Якщо користувач погодиться, система «запам'ятає» дані. При цьому гроші списуватимуться лише після того, як клієнт введе код безпеки CVV, що потрібно робити при кожній транзакції в Інтернеті.</p>
          </div>
          <div className="item">
            <h2>Робота з рахунками</h2>
            <p>Продаєте лише через соцмережі, без повноцінного інтернет-магазину? Удосконаліть систему прийому оплати за товари та послуги – виставляйте рахунки за посиланням. Отримавши її поштою або смс, клієнт зможе перейти на сторінку платежу і розрахуватися.</p>
          </div>
        </div>
      </div>
      <div className="tariffs">
        <h2>Тарифи</h2>
        <h3>Оплата кредитною або дебетовою карткою / готівкою</h3>
        <div className="blocks">
          {/* <div className="item">
            <div>
              <p>Оборот компанії,</p>
              <p>до 100 000 грн/місяць</p>
            </div>
            <div className="bot">
              <h3>2.7</h3>
              <p>% від суми платежу</p>
            </div>
          </div> */}
          <div className="item">
            <div>
              <p>Оборот компанії,</p>
              <p>до 500 000/місяць</p>
            </div>
            <div className="bot">
              <h3>1.5</h3>
              <p>% від суми платежу</p>
            </div>
          </div>
          <div className="item">
            <div>
              <p>Оборот компанії,</p>
              <p>більше 500 000/місяць</p>
            </div>
            <div className="bot">
              <h4>Індивідуально</h4>
            </div>
          </div>
        </div>
        <h3 className="pay-mob">Оплата з мобільного</h3>
        <div className="horizontal-block">
          <p>від </p>
          <h3>3.0 </h3>
          <p>% від суми платежу</p>
        </div>
        <p className="info">Система EasyPay працює на прозорих умовах без прихованих комісійних платежів. Якщо ваш оборот більше 500 000.0 грн./міс., ми готові запропонувати вам спеціальні тарифи на прийом готівки та коштів із пластикових карток. Чи є питання щодо ціноутворення? Будь ласка, залиште свій коментар на сайті і ми вийдемо на зв'язок у зручний для вас час.</p>
        <button className="last-btn" onClick={() => document.querySelector('.form').scrollIntoView({ behavior: 'smooth' })}>Підключити</button>
      </div>
      <div className="license">
        <h2>Безпека</h2>
        <h3>Платформа EasyPay працює з дотриманням міжнародних стандартів захисту онлайн-транзакцій.</h3>
        <h3>Сертифікація PCI DSS (Level 1)</h3>
        <img className="license-img" src="/images/license-easypay.svg" />
      </div>
      <div className="form">
        <h2>Ми з вами зв'яжемось</h2>
        <div className="form-inputs">
          <TextField
            label="Ім'я"
            name='firstName'
            id='firstName'
            inputRef={inputRef}
            size="small"
            value={firstName}
            onChange={(event => setFirstName(event.target.value))}
            onKeyDown={(e) => { if (e.keyCode == 13) inputRef2.current.focus() }}
          />
          <InputMask
            mask="+380 99 999 99 99"
            value={phoneNumber}
            beforeMaskedValueChange={beforeMaskedValueChange}
            maskChar=""
            inputMode="numeric"
            onChange={(event => changePhoneNumber(event))}
            onKeyDown={(e) => { if (e.keyCode == 13) inputRef3.current.focus() }}
          >
            {(inputProps) => <TextField inputRef={inputRef2} inputMode="numeric" {...inputProps} size="small" label="Номер телефону" />}
          </InputMask>
          <TextField
            label="Пошта"
            name='email'
            id='email'
            size="small"
            value={email}
            onChange={(event => handleEmail(event.target.value))}
            inputRef={inputRef3}
            onKeyDown={(e) => {
              if ((firstName && phoneNumber && btnEm)) {
                if (e.keyCode == 13) setLendingDataUser(lastName, firstName, phoneNumber, email)
              }
            }
            }
          />
          <button className={(!firstName || !phoneNumber || !btnEm) ? "send-btn-lending disabled" : 'send-btn-lending  '}
            disabled={!firstName || !phoneNumber || !btnEm}
            onClick={() => setLendingDataUser(lastName, firstName, phoneNumber, email)}>
            {/* Надіслати */}
            {apiMessage && 'Дані отримано'}
            {!apiMessage && 'Надіслати'}
          </button>
        </div>
      </div>
      <div className="footter">
        <div className="colum">
          <div className="icons">
            <img src="/images/image.svg" />
            <div className="icons-row">
              <img src="/images/Vector.svg" />
              <img src="/images/layer1.svg" />
            </div>
          </div>
          {/* <div className="col-info">
            <h2>Як це працює</h2>
            <p onClick={() => window.open('https://acquiring.easypay.ua/how-it-works/connection')}>Підключення платіжної системи</p>
            <p onClick={() => window.open('https://acquiring.easypay.ua/how-it-works/payment-methods')}>Способи оплати в інтернеті</p>
            <p onClick={() => window.open('https://acquiring.easypay.ua/how-it-works/payment-methods/cards')}>Оплата банківською карткою</p>
            <p onClick={() => window.open('https://acquiring.easypay.ua/how-it-works/payment-methods/terminals')}>Оплата через термінали</p>
          </div>
          <div className="col-info two">
            <p onClick={() => window.open('https://acquiring.easypay.ua/how-it-works/processing')}>Процесинг</p>
            <p onClick={() => window.open('https://acquiring.easypay.ua/how-it-works/security-payments')}>Безпека платежів</p>
            <p onClick={() => window.open('https://acquiring.easypay.ua/documentation')}>Документація</p>
          </div> */}
          <div className="col-info">
            <h2>Контакти</h2>
            <div className="contacts-row">
              <p className="p-bold">Адреса</p>
              <p onClick={() => navigator.clipboard.writeText('Київ, вул.Межигірська, 82А, корп.')} className="p-thin">: Київ, вул.Межигірська, 82А, корп.</p>
            </div>
            <div className="contacts-row">
              <p className="p-bold">Тел.</p>
              <p onClick={() => navigator.clipboard.writeText('0800603003')} className="p-thin">: 0 800 60 30 03</p>
            </div>
            <div className="contacts-row">
              <p className="p-bold">E-mail</p>
              <p onClick={() => navigator.clipboard.writeText('support@easypay.ua')} className="p-thin">: support@easypay.ua</p>
              <p onClick={() => navigator.clipboard.writeText('sales@easypay.ua')} className="p-thin">/ sales@easypay.ua</p></div>
            <div className="contacts-row gap">
              <p className="p-thin">Ми у соц. мережах:</p>
              <img className="touch" onClick={() => window.open('https://www.facebook.com/easypayUA')} src="/images/s1.svg" />
              <img className="touch" onClick={() => window.open('https://twitter.com/easypay_ua')} src="/images/s2.svg" />
              <img className="touch" onClick={() => window.open('https://web.telegram.org/k/#@easypayofficial')} src="/images/telegram-plane.svg" />
              <img className="touch" onClick={() => window.open('https://www.instagram.com/easypay.ua/')} src="/images/instagram.svg" />
              <img className="touch" onClick={() => window.open('https://www.youtube.com/channel/UCnFvHgLCXptpvdVmNLwrAeg')} src="/images/youtube.svg" />
              {/* <InstagramIcon className="touch" color="primary" onClick={() => window.open('https://www.instagram.com/easypay.ua/')} />
              <TelegramIcon className="touch" color="primary" onClick={() => window.open('https://web.telegram.org/k/#@easypayofficial')} />
              <YouTubeIcon className="touch" color="primary" onClick={() => window.open('https://www.youtube.com/channel/UCnFvHgLCXptpvdVmNLwrAeg')} /> */}
            </div>
          </div>
        </div>
        <p className="foot">©2009-2017 EasyPay. All rights reserved.</p>
      </div>
      <div className="version">
        1.2_18.09.24
      </div>
      {/* <Sidebar active={sideBarActive} setActive={setSideBarActive} component={component} setComponent={setComponent} /> */}
    </div >
  )

}

export default Layout;